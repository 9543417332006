<template>
  <v-container
    fluid
    fill-height
    :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''"
  >
    <v-card
      class="clients-section-wrapper mt-n1"
    >
      <v-tabs
        v-model="selectedTab"
        active-class="white"
        slider-color="accent"
        grow
        :hide-slider="!account.companyId"
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
        :class="$vuetify.breakpoint.mobile ? 'sticky-client-mobile-tabs' : 'mt-1'"
      >
        <v-tab
          v-for="(t, index) in actualTabs"
          :key="index"
          :disabled="t.disabled"
          :style="actualTabs.length === 1 ? 'color: black; opacity: 1.0; pointer-events: none;' : ''"
          @change="setClientTab(index)"
        >
          <div>
            <div>{{ t.text }}</div>
            <div
              style="font-size:10px;"
            >
              {{ t.title }}
            </div>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="selectedTab"
      >
        <v-tab-item
          v-for="(t, i) in actualTabs"
          :key="i"
        >
          <ClientsTabs
            :company="t.company"
            :group="t.group"
            :is-company-tab="t.isCompanyTab"
            :is-group-tab="t.isGroupTab"
            :is-my-tab="t.isMyTab"
            :user-role="t.userRole"
            :is-single-acc="t.isSingleAcc"
            :is-shared-with-me="t.isSharedWithMe"
            :is-lavvira-tab="t.isLavviraTab"
            :actual-tab="t"
            style="width: 100%;"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'
import ClientsTabs from './ClientsTabs.vue'

export default {
  components: {
    ClientsTabs
  },
  mixins: [generalMixin],
  data () {
    return {
      selectedTab: Number(localStorage.getItem('activeClientsTabIndex')) || 0
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      documents: state => state.documents.documents
    }),
    actualTabs () {
      let tabs = []
      if (!this.company && !this.account.companyId) {
        tabs.push({
          title: this.$t('clients|clients'),
          text: this.$t('common|my'),
          isCompanyTab: false,
          isGroupTab: false,
          isSingleAcc: true,
          isMyTab: true,
          isLavviraTab: false
        })
      }
      if (this.company && this.account.companyId) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole => generalMixin
        if (userRole !== 'regular') {
          tabs.push({
            title: this.$t('clients|clients'),
            text: this.$t('common|my'),
            userRole: userRole,
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: false,
            isLavviraTab: false,
            isMyTab: true
          })
          tabs.push({
            title: this.$t('clients|clients'),
            text: this.company.companyData.companyName,
            company: this.company,
            isCompanyTab: true,
            isGroupTab: false,
            userRole: userRole,
            isSingleAcc: false,
            isLavviraTab: false,
            isMyTab: false,
            disabled: this.company.subscriptionStatus === 'unpaid' || false
          })
          if (this.company.groups && this.company.groups.length) {
            for (let i = 0; i < this.company.groups.length; i++) {
              const group = this.company.groups[i]
              const accountParticipation = group.groupMembers.findIndex(gm => gm._id === this.account._id)
              if (accountParticipation !== -1) {
                tabs.push({
                  title: this.$t('clients|clients'),
                  text: group.groupName,
                  group: group,
                  isCompanyTab: false,
                  isGroupTab: true,
                  userRole: userRole,
                  isSingleAcc: false,
                  isLavviraTab: false,
                  isMyTab: false,
                  disabled: this.company.subscriptionStatus === 'unpaid' || false
                })
              }
            }
          }
        } else if (userRole === 'regular') {
          tabs.push({
            title: this.$t('clients|clients'),
            text: this.company.companyData.companyName,
            company: this.company,
            isCompanyTab: true,
            isGroupTab: false,
            isMyTab: false,
            userRole: userRole
          })
          if (this.company.groups && this.company.groups.length) {
            for (let i = 0; i < this.company.groups.length; i++) {
              const group = this.company.groups[i]
              const accountParticipation = group.groupMembers.findIndex(gm => gm._id === this.account._id)
              if (accountParticipation !== -1) {
                tabs.push({
                  title: this.$t('clients|clients'),
                  text: group.groupName,
                  group: group,
                  isCompanyTab: false,
                  isGroupTab: true,
                  isMyTab: false,
                  userRole: userRole,
                  isLavviraTab: false,
                  disabled: this.company.subscriptionStatus === 'unpaid' || false
                })
              }
            }
          }
        }
      }
      return tabs
    }
  },
  methods: {
    setClientTab (idx) {
      localStorage.setItem('activeClientsTabIndex', idx)
    }
  }
}
</script>

<style lang="scss">
  .clients-section-wrapper {
    width: 100%;
    height: 100%;
  }
  .sticky-client-mobile-tabs {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
</style>
